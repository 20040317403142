import { ApiService } from 'services/ApiService';

import {
  DownloadPresentationDTO,
  LanguagesDTO,
  LayoutDTO,
  PresentationAsyncDTO,
  PresentationAsyncStatusDTO,
  PresentationData,
  PresentationDTO,
  PresentationGetDTO,
} from './presentation.dto';

export const generatePresentation = (data: PresentationData): Promise<PresentationDTO> =>
  ApiService.post('/presentation', data);

export const asyncPresentation = async (data: PresentationData) => {
  const id = await generatePresentationAsync(data);
  let res = {} as PresentationAsyncStatusDTO;
  return new Promise<PresentationAsyncStatusDTO>((resolve, reject) => {
    const statusInterval = setInterval(async () => {
      try {
        res = await getPresentationAsyncStatus(id);
      } catch (e) {
        reject(e);
        clearInterval(statusInterval);
      }
      if (res.status === 'done') {
        resolve(res);
        clearInterval(statusInterval);
      }
    }, 1000);
  });
};

export const generatePresentationAsync = (data: PresentationData): Promise<PresentationAsyncDTO> =>
  ApiService.post('/presentations/generator/start', data);

export const getPresentationAsyncStatus = (data: PresentationAsyncDTO): Promise<PresentationAsyncStatusDTO> =>
  ApiService.post('/presentations/generator/status', data);

export const getPresentationById = (id: string) => ApiService.post<PresentationGetDTO>('/presentations/get', { id });

export const getPresentationPPTX = (id: string): Promise<DownloadPresentationDTO> =>
  ApiService.post('/presentations/generate_file/pptx', { id });

export const getLayout = () => ApiService.post<LayoutDTO>('/presentations/layouts/list');

export const getLanguages = () => ApiService.post<LanguagesDTO>('/helpers/languages');

export const deletePresentationById = (id: string) => ApiService.post('/presentations/delete', { id });
