import { createRoutesFromElements, Route } from 'react-router-dom';

import { Layout } from 'components/ui/Layout';
import { LayoutProfile } from 'components/ui/Layout/LayoutProfile';
import { prepareComponent } from 'utils/prepareComponent';
import { PublicPresentationPage } from 'pages/PresentationPage/PublicPresentationPage';

const PresentationsList = prepareComponent(
  import('./pages/PresentationsList').then((module) => ({ default: module.PresentationsList }))
);

const PresentationPage = prepareComponent(
  import('./pages/PresentationPage').then((module) => ({ default: module.PresentationPage }))
);

const Home = prepareComponent(import('./pages/Home').then((module) => ({ default: module.Home })));

const Profile = prepareComponent(import('./pages/Profile').then((module) => ({ default: module.Profile })));

export const router = createRoutesFromElements(
  <>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="presentation/:id" element={<PresentationPage />} />
      <Route path="presentation/public/:id" element={<PublicPresentationPage />} />
      <Route path="presentation" element={<PresentationsList />} />
    </Route>
    <Route path="/profile" element={<LayoutProfile />}>
      <Route index element={<Profile />} />
    </Route>
  </>
);
