/* eslint-disable */
import { ApiMethod } from './types';

export const ApiService = {
  options: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
    crossDomain: true,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async request<T = any>(
    method: ApiMethod,
    url: string,
    params?: Record<string, any>,
  ): Promise<T> {
    const fullOptions = {
      ...this.options,
      method,
      body: JSON.stringify(params),
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await fetch(`/api/v1${url}`, fullOptions);
    const responceJson = await res.text();
    try {
      const responce = JSON.parse(responceJson);
      if (!res.ok) throw responce;
      if (responce.error) throw responce.error;
      return responce;
    } catch {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (!res.ok) throw JSON.parse(responceJson);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return responceJson;
    }
  },
  post<T>(url: string, params?: Record<string, any>) {
    return this.request<T>(ApiMethod.Post, url, params);
  },
  get<T>(url: string) {
    return this.request<T>(ApiMethod.Get, url, undefined);
  },
  put<T>(url: string, params?: Record<string, any>) {
    return this.request<T>(ApiMethod.Put, url, params);
  },
  delete<T>(url: string, params?: Record<string, any>) {
    return this.request<T>(ApiMethod.Delete, url, params);
  },
};
