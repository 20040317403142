import { default as SimpleBarModule } from 'simplebar-react';
import { styled } from '@mui/material';

export const SimpleBar = styled(SimpleBarModule)({
  maxHeight: '100%',
  ['& .simplebar-content']: {
    marginRight: '80px',
  },
  ['& .simplebar-scrollbar::before']: {
    backgroundColor: '#646464',
  },
});
