import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';

const StyledButtonText = styled(Typography)`
  background: linear-gradient(90deg, #ff5b64, #ffa066, #ff5b64) 0 0 / 400% 100%;
  color: transparent;
  --webkit-background-clip: text;
  --webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  font-size: 24px;
  text-transform: initial;

  animation: shine 8s linear infinite;
  @keyframes shine {
    to {
      background-position: 400% 0;
    }
  }
`;

export const ButtonShade: FC<LoadingButtonProps> = ({ children, ...props }) => {
  return (
    <LoadingButton {...props}>
      <StyledButtonText>{children}</StyledButtonText>
    </LoadingButton>
  );
};
