import { useState } from 'react';
import { Box, Drawer, IconButton, styled, Typography, typographyClasses } from '@mui/material';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from 'assets/icons/closeDark.svg';
import { ReactComponent as Checked } from 'assets/icons/checked.svg';
import { useLayout } from 'queries/useLayout';
import { useLayoutForm } from 'hooks/use-layout-form';

const Root = styled('div')({
  flexGrow: 0,
});

const LangItem = styled(Box)({
  padding: '12px 36px 12px 28px',
  marginLeft: '-28px',
  display: 'flex',
  justifyContent: 'space-between',
  [`& .${typographyClasses.root}`]: {
    color: '#ffffff',
    textTransform: 'capitalize',
    fontFamily: 'IBM Plex Mono',
  },
  [`&.active .${typographyClasses.root}`]: {
    color: '#3376F6',
  },
  ['&:hover']: {
    backgroundColor: '#525252',
    cursor: 'pointer',
  },
});

export function LanguageMenu() {
  const [isOpened, setIsOpened] = useState(false);
  const { languages } = useLayout();
  const { setLang, language } = useLayoutForm();

  const toggleDrawer = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Root>
      <Typography onClick={toggleDrawer} variant="tag" sx={{ textTransform: 'capitalize', cursor: 'pointer' }}>
        Presentation Language: {language}
      </Typography>
      <Drawer
        anchor="left"
        open={isOpened}
        onClose={toggleDrawer}
        slotProps={{ backdrop: { sx: { background: 'initial' } } }}
        PaperProps={{
          elevation: 0,
          sx: {
            background: 'initial',
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
            boxShadow: '32px 0px 48px rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        <Box
          width={`${496 / 16}rem`}
          height="100%"
          bgcolor="#3D3D3D"
          padding="24px 0 24px 28px"
          display="grid"
          gridTemplateRows="auto 1fr"
        >
          <Box display="flex" justifyContent="space-between" pr="10px" pb="24px">
            <Typography variant="h4" sx={{ fontSize: '36px', fontWeight: 700 }} color="#ffffff">
              Presentation Language
            </Typography>
            <IconButton onClick={toggleDrawer} sx={{ marginTop: '-10px' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            {languages.data?.languages.map((item) => (
              <LangItem
                onClick={() => {
                  setLang(item);
                  toggleDrawer();
                }}
                key={item}
                className={clsx(language === item ? 'active' : '')}
              >
                <Typography variant="body1">{item}</Typography>
                {language === item && (
                  <div>
                    <Checked />
                  </div>
                )}
              </LangItem>
            ))}
          </Box>
        </Box>
      </Drawer>
    </Root>
  );
}
