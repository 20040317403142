import { useParams } from 'react-router-dom';

import { SimpleBar } from 'components/ui/SimpleBar';
import { useProfile } from 'queries/useProfile';
import { usePublicPresentation } from 'queries/useLayout';
import { prepareComponent } from 'utils/prepareComponent';

const LayoutForm = prepareComponent(
  import('../../components/ui/LayoutForm').then((module) => ({ default: module.LayoutForm }))
);

const Presentation = prepareComponent(
  import('../../components/features/Presentation').then((module) => ({ default: module.Presentation }))
);

export const PublicPresentationPage = () => {
  const params = useParams<{ id: string }>();
  const { profile } = useProfile();
  const { presentation } = usePublicPresentation(params.id);

  const isEditable = presentation.data ? presentation.data?.presentation.user_id === profile.data?.id : false;

  return (
    <>
      <SimpleBar sx={{ width: '20%' }}>
        <LayoutForm />
      </SimpleBar>
      {presentation.data && (
        <SimpleBar sx={{ width: '80%' }}>
          <Presentation presentation={presentation.data.presentation} isEditable={isEditable} />
        </SimpleBar>
      )}
    </>
  );
};
