import { useIsMutating, useMutation } from 'react-query';

import { openSession as openSessionQuery, closeSession as closeSessionQuery } from 'api/session';
import { useCookie } from 'hooks/use-cookie';

export const useSession = () => {
  const [_, setEmail] = useCookie('hasEmail', '');
  const openSession = useMutation(() => openSessionQuery(), {
    mutationKey: 'open-session',
  });

  const closeSession = useMutation(() => closeSessionQuery(), {
    onSuccess() {
      setEmail('', 1);
      window.location.reload();
    },
  });

  const isOpening = useIsMutating({
    mutationKey: 'open-session',
  });

  return {
    closeSession,
    openSession,
    isOpening,
  };
};
