import 'simplebar-react/dist/simplebar.min.css';

import { useRoutes } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';

import { Mock } from 'components/ui/Mock';
import { useCookie } from 'hooks/use-cookie';
import { useSession } from 'queries/useSession';
import { router } from 'router';

function App() {
  const element = useRoutes(router);
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('md'));
  const { openSession, isOpening } = useSession();
  const [sessionCookie] = useCookie('session_id', '');
  useEffect(() => {
    if (!sessionCookie && isOpening === 0) {
      openSession.mutate();
    }

    const body = document.querySelector('body');
    const loadingScreen = document.querySelector('.loading-screen');
    if (loadingScreen) {
      loadingScreen.classList.add('loading-screen--hide');
      setTimeout(() => {
        loadingScreen.classList.add('hidden');
        if (body) {
          body.style.overflow = 'initial';
        }
      }, 1000);
    }
  }, []);

  if (!isDesktop) {
    return <Mock />;
  }

  return <>{element}</>;
}

export default App;
