import { Components } from '@mui/material';

export const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      borderRadius: '6px',
      padding: '6px 12px',
      fontSize: '15px',
    },
  },
  defaultProps: {
    arrow: true,
  },
};
