import { useQuery } from 'react-query';

import { getProfile } from 'api/profile';

export const useProfile = () => {
  const profile = useQuery(['profile'], getProfile, {
    retry: false,
    staleTime: Infinity,
  });

  return {
    profile,
  };
};
