import { styled } from '@mui/material';

export const Root = styled('div')({
  backgroundColor: '#333333',
  height: '100vh',
  padding: '0 24px',
  display: 'grid',
  gridTemplateAreas: "'main aside'",
  gridTemplateColumns: '1fr auto',
});

export const RootContent = styled('div')({
  backgroundColor: '#333333',
  height: '100vh',
  display: 'grid',
  gridTemplateAreas: `
  "header"
  "main"
  "controls"
  "footer"
  `,
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr auto',
});
