import React, { createContext, FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { PresentationAsyncStatusDTO, PresentationData } from 'api/presentation.dto';
import { useCookie } from 'hooks/use-cookie';
import { usePresentation } from 'queries/useLayout';

type LayoutFormContextType = {
  form: UseFormReturn<PresentationData> | null;
  presentationData: PresentationAsyncStatusDTO | null;
  setPresentation: (data: PresentationAsyncStatusDTO | null) => void;
  toggleIsError: (flag?: boolean) => void;
  isError: boolean;
  language: string;
  setLang: (lang: string) => void;
};

export const LayoutFormContext = createContext<LayoutFormContextType>({
  form: null,
  presentationData: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPresentation: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleIsError(flag?: boolean) {
    return;
  },
  isError: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLang: () => {},
  language: 'english',
});

export const LayoutFormProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const params = useParams<{ id: string }>();
  const { presentation } = usePresentation(params.id);
  const form = useForm<PresentationData>({
    defaultValues: {
      ProjectName: presentation.data?.presentation.title || '',
      ProjectDescription: presentation.data?.presentation.description || '',
      Target: presentation.data?.presentation.target || '',
    },
  });
  const [presentationData, setPresentationData] = useState<PresentationAsyncStatusDTO | null>(null);
  const [isError, setIsErrorState] = useState(false);
  const [presentationLanguage, setPresentationLanguage] = useState('english');
  const setPresentation = (data: PresentationAsyncStatusDTO | null) => {
    setPresentationData(data);
  };
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [langCookie, updateLangCookie] = useCookie('lang', 'english');
  const setLang = useCallback(
    (language: string) => {
      setPresentationLanguage(language);
      updateLangCookie(language, 30);
    },
    [updateLangCookie]
  );

  const toggleIsError = useCallback(
    (flag?: boolean) => {
      if (flag === undefined) {
        setIsErrorState(!isError);
      } else {
        setIsErrorState(flag);
      }
    },
    [isError]
  );

  useEffect(() => {
    if (langCookie !== presentationLanguage) {
      setPresentationLanguage(langCookie);
    }
  }, [langCookie, presentationLanguage]);

  useEffect(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    if (isError) {
      timeoutRef.current = setTimeout(() => {
        toggleIsError(false);
      }, 5000);
    }
  }, [isError, toggleIsError]);

  return (
    <LayoutFormContext.Provider
      value={{
        form,
        presentationData,
        setPresentation,
        toggleIsError,
        isError,
        language: presentationLanguage,
        setLang,
      }}
    >
      {children}
    </LayoutFormContext.Provider>
  );
};
