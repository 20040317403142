import { ApiService } from 'services/ApiService';

import { OpenSessionDTO, SendOTPParams, VerifyOTPParams } from './session.dto';

export const openSession = () => ApiService.post<OpenSessionDTO>('/auth/session/open');

export const closeSession = () => ApiService.post<OpenSessionDTO>('/auth/session/close');

export const sendOtp = (data: SendOTPParams) => ApiService.post<{ message: string }>('/auth/otp/send', data);

export const confirmOtp = (data: VerifyOTPParams) => ApiService.post('/auth/otp/verify', data);
