import { Box, Stack, styled } from '@mui/material';
// import { ReactComponent as AboutIcon } from 'assets/icons/about.svg';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { RoundButton } from 'components/ui/RoundedButton/RoundedButton';

const Root = styled('aside')({
  gridArea: 'aside',
  minWidth: '113px',
  padding: '24px 0 16px',
  display: 'grid',
  gridTemplateAreas: `
    "buttons"
    "logo"
  `,
  gridTemplateRows: '1fr auto',
});

export const AsideProfile = () => {
  const navigation = useNavigate();

  return (
    <Root>
      <Stack gridArea="buttons" alignItems="flex-end" spacing={2}>
        <RoundButton sx={{ overflow: 'hidden' }} onClick={() => navigation('/')}>
          <Close />
        </RoundButton>
      </Stack>
      <Box display="flex" justifyContent="end">
        <Logo width="93px" height="48px" />
      </Box>
    </Root>
  );
};
