import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import { LayoutFormProvider } from 'providers/LayoutFormProvider';

import { Header } from '../Header';

import { Aside } from './components/Aside';
import { Footer } from './components/Footer';
import { LanguageMenu } from './components/LanguageMenu';
import { Root, RootContent } from './Layout.styles';

export const Layout = () => {
  const { pathname } = useLocation();

  return (
    <Root>
      <LayoutFormProvider>
        <RootContent>
          {pathname !== '/presentation' && (
            <Box gridArea="header">
              <Header />
            </Box>
          )}
          <Box gridArea="main" overflow="hidden" display="flex">
            <Outlet />
          </Box>
          {pathname !== '/presentation' && (
            <Box gridArea="controls" display="flex" gap="8px" mb="10px">
              <LanguageMenu />
            </Box>
          )}
          <Footer />
        </RootContent>
        <Aside />
      </LayoutFormProvider>
    </Root>
  );
};
