import { Link, styled, Typography } from '@mui/material';

import { useLayoutForm } from 'hooks/use-layout-form';

// import { BurgerMenu } from './components/BurgerMenu';

const Root = styled('div')({
  display: 'flex',
  paddingTop: '24px',
  position: 'relative',
  alignItems: 'center',
  // ['&:after']: {
  //   content: '""""',
  //   display: 'block',
  //   height: '70px',
  //   width: '100%',
  //   background: 'linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0) 100%)',
  //   position: 'absolute',
  //   bottom: '-70px',
  //   zIndex: '1',
  // },
  marginBottom: '32px',
});

const StyledText = styled(Typography)({
  display: 'inline-block',
  minHeight: '28px',

  fontFamily: 'IBMPlexMono',
  fontSize: '17px',
  fontWeight: 700,
  lineHeight: '20px',
  textDecoration: 'none',
  letterSpacing: '0.03em',

  padding: '3px 8px',
  borderRadius: '6px',
});

export const Header = () => {
  const { presentationData } = useLayoutForm();
  return (
    <Root sx={{ marginBottom: presentationData ? 0 : '32px' }}>
      {!presentationData && (
        <>
          {/* <BurgerMenu /> */}
          <Typography color={({ palette }) => palette.primary.dark}>
            <Typography variant="tagXS" component={Link} href="https://experiments.self.team">
              experiments
            </Typography>
            &nbsp;
            {' / '}
            &nbsp;
            <Typography variant="tagXS" component={Link} href="https://experiments.self.team/chatgpt">
              #GPT-3
            </Typography>
            &nbsp;
            {' / '}
            &nbsp;
            <StyledText color="#525252">peachapp</StyledText>
          </Typography>
        </>
      )}
    </Root>
  );
};
