import { Components } from '@mui/material';

export const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: {
      color: '#ffffff',
    },
  },
  defaultProps: {
    variant: 'standard',
  },
};
