import { useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  asyncPresentation,
  deletePresentationById,
  generatePresentation,
  getLanguages,
  getLayout,
  getPresentationById,
  getPresentationPPTX,
} from 'api/presentation';
import { LayoutDTO, PresentationData, PresentationGetDTO } from 'api/presentation.dto';
import { PresentationListDTO } from 'api/presentationList.dto';
import { useLayoutForm } from 'hooks/use-layout-form';
import { getPublicPresentationById } from 'api/presentationPublic';

export const useLayout = () => {
  const queryClient = useQueryClient();
  const presentation = useMutation((data: PresentationData) => generatePresentation(data), {
    mutationKey: 'presentation',
  });

  const presentationAsync = useMutation((data: PresentationData) => asyncPresentation(data), {
    mutationKey: 'presentationAsync',
    onSuccess() {
      queryClient.invalidateQueries(['presentations']);
    },
  });

  const isGenerating = useIsMutating({
    mutationKey: 'presentationAsync',
    exact: true,
  });

  const pptx = useMutation((id: string) => getPresentationPPTX(id), {
    onSuccess: (data) => {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.href = `${process.env.REACT_APP_BASE_URL}${data.result}`;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link.parentNode.removeChild(link);
      }, 0);
    },
  });

  const deletePresentation = useMutation((id: string) => deletePresentationById(id), {
    async onMutate(variables) {
      await queryClient.cancelQueries(['presentations']);
      const prevData = queryClient.getQueryData<PresentationListDTO | undefined>(['presentations']);

      queryClient.setQueryData<PresentationListDTO | undefined>(['presentations'], (oldData) => {
        if (oldData) {
          const presentationIndex = oldData.presentations.findIndex((v) => v.id === variables);
          if (presentationIndex !== -1) {
            const newPresentations = [...oldData.presentations];
            newPresentations.splice(presentationIndex, 1);
            return {
              presentations: newPresentations,
            };
          }
        }
        return oldData;
      });

      return { prevData };
    },
    onError(err, variables, context) {
      queryClient.setQueryData(['presentations'], context?.prevData);
    },
  });

  const layout = useQuery('layout', () => getLayout(), {
    staleTime: Infinity,
  });

  const languages = useQuery('languages', () => getLanguages(), {
    staleTime: Infinity,
  });

  const getSlideLayout = (name: keyof LayoutDTO) => {
    if (layout.data) {
      return layout.data[name];
    }
    return undefined;
  };

  return {
    presentation,
    presentationAsync,
    isGenerating,
    pptx,
    layout,
    languages,
    getSlideLayout,
    deletePresentation,
  };
};

export const usePresentation = (id?: string) => {
  const { form } = useLayoutForm();
  const presentation = useQuery(['presentation', id], () => (id ? getPresentationById(id) : undefined), {
    refetchOnMount: false,
    onSuccess(data: PresentationGetDTO) {
      if (form && data) {
        const values = form?.getValues();
        if (!values.ProjectName) {
          form.reset({
            Target: data.presentation.target,
            ProjectDescription: data.presentation.description,
            ProjectName: data.presentation.title,
          });
        }
      }
    },
    staleTime: Infinity,
  });

  return {
    presentation,
  };
};

export const usePublicPresentation = (id?: string) => {
  const { form } = useLayoutForm();
  const presentation = useQuery(['presentation-public', id], () => (id ? getPublicPresentationById(id) : undefined), {
    refetchOnMount: false,
    onSuccess(data: PresentationGetDTO) {
      if (form && data) {
        const values = form?.getValues();
        if (!values.ProjectName) {
          form.reset({
            Target: data.presentation.target,
            ProjectDescription: data.presentation.description,
            ProjectName: data.presentation.title,
          });
        }
      }
    },
    staleTime: Infinity,
  });

  return {
    presentation,
  };
};
