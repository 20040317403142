import { useCallback, useState } from 'react';

interface UseToggleActions {
  handleToggle: () => void;
  handleOpen: () => void;
  handleClose: () => void;
}

export const useToggle = (initialOpen = false): readonly [boolean, UseToggleActions] => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleToggle = useCallback(() => setIsOpen((prev) => !prev), []);

  return [isOpen, { handleClose, handleOpen, handleToggle }];
};
