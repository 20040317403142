import { useMutation, useQueryClient } from 'react-query';

import { confirmOtp, sendOtp } from 'api/session';
import { SendOTPParams, VerifyOTPParams } from 'api/session.dto';

export const useAuthQuery = () => {
  const queryClient = useQueryClient();
  const auth = useMutation((data: SendOTPParams) => sendOtp(data));
  const confirm = useMutation((data: VerifyOTPParams) => confirmOtp(data), {
    onSuccess() {
      queryClient.refetchQueries(['profile']);
    },
  });

  return {
    auth,
    confirm,
  };
};
