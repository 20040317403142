import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { AsideProfile } from './components/Aside';
import { Footer } from './components/Footer';
import { Root, RootContent } from './Layout.styles';

export const LayoutProfile = () => {
  return (
    <Root>
      <RootContent>
        <Box gridArea="header" />
        <Box gridArea="main" overflow="hidden" display="flex">
          <Outlet />
        </Box>
        <Box gridArea="controls" display="flex" gap="8px" />
        <Footer />
      </RootContent>
      <AsideProfile />
    </Root>
  );
};
