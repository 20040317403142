import { Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography = (palette: Palette): TypographyOptions => {
  const tagInstance: TypographyOptions['tag'] = {
    display: 'inline-block',
    minHeight: '28px',

    fontFamily: 'IBMPlexMono',
    color: palette.primary.main,
    fontSize: '17px',
    fontWeight: 700,
    lineHeight: '20px',
    textDecoration: 'none',
    letterSpacing: '0.03em',

    backgroundColor: palette.primary.dark,

    padding: '3px 8px',
    borderRadius: '6px',
  };

  const tagLightInstance: TypographyOptions['tagLight'] = {
    ...tagInstance,
    color: '#141414',
    backgroundColor: palette.primary.main,
  };

  return {
    fontFamily: 'Helvetica Neue',
    h1: {
      fontWeight: 700,
      fontSize: '4rem',
      letterSpacing: '-0.04em',
      fontFeatureSettings: "'pnum' on, 'lnum' on, 'ss01' on, 'ss03' on, 'ss04' on",
    },
    heming: {
      fontFamily: 'IBMPlexMono',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '21px',
      lineHeight: '24px',
      /* identical to box height, or 114% */

      letterSpacing: '0.04em',

      /* The Lightest Ever Grey */

      color: palette.primary.main,
    },
    hemingGrey: {
      fontFamily: 'IBMPlexMono',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '21px',
      lineHeight: '24px',
      /* identical to box height, or 114% */

      letterSpacing: '0.04em',

      /* The Lightest Ever Grey */

      color: palette.primary.dark,
    },
    tagXS: {
      ...tagInstance,
      fontSize: '17px',
      padding: '2px 7px 4px',
    },
    tagXSLight: {
      ...tagInstance,
      ...tagLightInstance,
      fontSize: '15px',
      padding: '3px 6px',
    },
    tag: tagInstance,
    tagLight: tagLightInstance,
    tagM: {
      ...tagInstance,
      fontSize: '24px',
      padding: '12px 16x',
    },
    link: {
      textDecoration: 'none',
      color: '#646464',
      '&:hover': {
        color: '#ffffff',
      },
    },
  };
};
