import { createTheme } from '@mui/material';

import { palette } from './palette';
import * as components from './components';
import { typography } from './typography';

export const theme = createTheme({
  typography: typography(palette),
  components,
  palette: palette,
});
