/* eslint-disable */
import { Box } from '@mui/material';

export const Mock = () => {
  return (
    <Box sx={{ background: '#1f1f1f', whiteSpace: 'pre-wrap', width: '100%' }}>
      <div className="content-bounds" style={{ width: '320px', height: '786px' }}>
        <div className="page-content-container" style={{ width: '320px', height: '568px', top: '0px', left: '0px' }}>
          <div
            className="animation-container"
            style={{
              transform: 'matrix(1, 0, 0, 1, 0, 0)',
              opacity: 1,
              left: '16px',
              top: '16px',
              width: '288px',
              height: '192px',
              zIndex: 302,
            }}
          >
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '@keyframes animation_1_1 {\n\t0% {transform: matrix(1,0,0,1,0,0); opacity: 0; animation-timing-function: linear}\n\t100% {transform: matrix(1,0,0,1,0,0); opacity: 1; }\n}\n',
              }}
            />
            <div
              className="rmwidget widget-text-v3"
              data-id="63da7acf93360b0021e05e70"
              style={{ left: '0px', top: '0px', width: '288px', height: '192px', zIndex: 302 }}
            >
              <div className="css-1u7vnjx eidf37h0">
                <div className="text-viewer">
                  <p
                    style={{
                      lineHeight: '24px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      So sorry, but experiments is&nbsp;only available on&nbsp;desktop. please, open it in&nbsp;your
                      computer browser or&nbsp;wait while we make a&nbsp;mobile version. Hope you understand, we&apos;re
                      just experimenting with technologies&nbsp;:)
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="animation-container"
            style={{
              transform: 'matrix(1, 0, 0, 1, 0, 0)',
              opacity: 1,
              left: '16px',
              top: '220px',
              width: '288px',
              height: '110px',
              zIndex: 303,
            }}
          >
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '@keyframes animation_2_1 {\n\t0% {transform: matrix(1,0,0,1,0,0); opacity: 0; animation-timing-function: linear}\n\t100% {transform: matrix(1,0,0,1,0,0); opacity: 1; }\n}\n',
              }}
            />
            <div
              className="rmwidget widget-text-v3"
              data-id="63e0412eead20c425cfe7731"
              style={{ left: '0px', top: '0px', width: '288px', height: '110px', zIndex: 303 }}
            >
              <div className="css-1u7vnjx eidf37h0">
                <div className="text-viewer">
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    >   ________</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    >  |        |</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    >  |        |</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    > ––––––––––––</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    > ––––––––––––</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="animation-container"
            style={{
              transform: 'matrix(1, 0, 0, 1, 0, 0)',
              opacity: 1,
              left: '16px',
              top: '342px',
              width: '288px',
              height: '132px',
              zIndex: 304,
            }}
          >
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '@keyframes animation_3_1 {\n\t0% {transform: matrix(1,0,0,1,0,0); opacity: 0; animation-timing-function: linear}\n\t100% {transform: matrix(1,0,0,1,0,0); opacity: 1; }\n}\n',
              }}
            />
            <div
              className="rmwidget widget-text-v3"
              data-id="63e041fe369ea14273e0dec5"
              style={{ left: '0px', top: '0px', width: '288px', height: '132px', zIndex: 304 }}
            >
              <div className="css-1u7vnjx eidf37h0">
                <div className="text-viewer">
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    >  __________</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    > |          |</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    > |          |</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    >
                      {' '}
                      |__________|
                    </span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    >     |  |</span>
                  </p>
                  <p
                    style={{
                      lineHeight: '22px',
                      paddingBottom: 0,
                      fontFamily: 'IBM Plex Mono',
                      fontWeight: 500,
                      fontSize: '17px',
                    }}
                    className="view-mode unstyled align-left"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontFamily: 'IBM Plex Mono',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-0.3px',
                        fontSize: '17px',
                      }}
                    >    ––––––</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="animation-container"
            style={{
              transform: 'matrix(1, 0, 0, 1, 0, 0)',
              opacity: 0,
              left: '16px',
              top: '494px',
              width: '9px',
              height: '22px',
              zIndex: 305,
              animationName: 'animation_4_1',
              animationDuration: '0.8s',
              animationDelay: '0s',
              animationIterationCount: 'infinite',
              animationDirection: 'alternate',
              animationFillMode: 'none',
            }}
          >
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '@keyframes animation_4_1 {\n\t0% {transform: matrix(1,0,0,1,0,0); opacity: 0; animation-timing-function: ease-in-out}\n\t100% {transform: matrix(1,0,0,1,0,0); opacity: 1; }\n}\n',
              }}
            />
            <div
              className="rmwidget widget-shape div-instead-of-svg"
              data-id="63da7acf93360b0021e05e71"
              style={{
                left: '0px',
                top: '0px',
                width: '9px',
                height: '22px',
                zIndex: 305,
                backgroundColor: 'rgb(255, 255, 255)',
                borderColor: 'rgb(255, 255, 255)',
                borderRadius: '0px',
                borderStyle: 'solid',
                borderWidth: '0px',
                boxSizing: 'border-box',
              }}
              role="img"
            />
          </div>
        </div>
      </div>
    </Box>
  );
};
