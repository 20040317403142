import { LoadingButton, loadingButtonClasses } from '@mui/lab';
import { buttonBaseClasses, styled } from '@mui/material';

export const RoundButton = styled(LoadingButton)(() => ({
  position: 'relative',

  width: '64px',
  height: '64px',

  flexDirection: 'column',

  borderRadius: '50%',
  backgroundColor: '#525252',
  transition: 'all .3s ease-in-out',
  ['&:hover']: {
    backgroundColor: '#525252',
  },
  ['& svg path']: {
    transition: 'fill .3s ease-in-out',
  },
  [`&.active:not(.${loadingButtonClasses.loading})`]: {
    backgroundColor: '#FC9F72',
    ['& svg path']: {
      fill: '#fff',
    },
  },
  [`&.active:not(.${loadingButtonClasses.loading}, .error):after, &.active:not(.${loadingButtonClasses.loading},.error):before`]:
    {
      content: '" " " "',
      display: 'block',
      position: 'absolute',
      backgroundColor: '#FC9F72',
      width: '76px',
      height: '76px',
      opacity: 0.1,
      borderRadius: '50%',
    },
  [`&.error`]: {
    width: '280px',
    height: '194px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: '24px',
    padding: '16px 16px 16px 20px',
    backgroundColor: '#E46346 !important',
    overflow: 'hidden',
    ['& p']: {
      textTransform: 'none',
      textAlign: 'left',
      lineHeight: '18px',
      letterSpacing: '0.04em',
      textOverflow: 'ellipsis',
      fontFamily: 'IBMPlexMono',
    },
  },
  [`&.active:not(.${loadingButtonClasses.loading}):before`]: {
    width: '88px',
    height: '88px',
  },
  [`&.${buttonBaseClasses.disabled}`]: {
    backgroundColor: '#3D3D3D',
    ['& svg path']: {
      fill: '#646464',
    },
  },
  [`&.${loadingButtonClasses.loading}`]: {
    backgroundColor: '#FFFFFF',
    ['& svg path']: {
      fill: '#000000',
    },
  },
  [`& .${loadingButtonClasses.loadingIndicator}`]: {
    color: '#FC9F72',
    transform: 'scale(4.5) translate(-8%)',
  },
}));
