import { Box, Divider, Link, styled, Typography } from '@mui/material';

import { useLayoutForm } from 'hooks/use-layout-form';

const FooterRoot = styled('footer')({
  gridArea: 'footer',
  padding: '7.5px 0 3.5px',
  marginRight: '80px',
});

export const Footer = () => {
  const { presentationData } = useLayoutForm();
  return (
    <FooterRoot>
      {!presentationData && (
        <>
          <Divider sx={{ backgroundColor: '#646464', mb: '7.5px' }} />
          <Box display="flex" justifyContent="space-between">
            <Typography color="#646464" fontWeight="400" mt="5px">
              Made by Experiments. Part of{' '}
              <Typography variant="link" component={Link} href="https://self.team/" target="_blank">
                Self_.
              </Typography>{' '}
              <br />
              Designed by{' '}
              <Typography variant="link" component={Link} href="https://m-y-s.co/" target="_blank">
                *M-Y-S*.
              </Typography>{' '}
              ©2023
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: `<a href="https://www.producthunt.com/posts/peach-app?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-peach&#0045;app" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=388892&theme=dark" alt="Peach&#0032;App - A&#0032;new&#0032;tool&#0032;for&#0032;your&#0032;great&#0032;pitches | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>`,
              }}
            />
          </Box>
        </>
      )}
    </FooterRoot>
  );
};
