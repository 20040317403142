/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, FC, ReactNode } from 'react';

import { useToggle } from 'hooks/use-toggle';
import { Authorization } from 'components/features/Authorization';

export const AuthorizationContext = createContext({
  openAuth: () => {},
});

export const AuthorizationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [authModalStatus, authModalActions] = useToggle();
  const openAuth = () => {
    authModalActions.handleOpen();
  };
  return (
    <AuthorizationContext.Provider
      value={{
        openAuth,
      }}
    >
      {children}
      <Authorization open={authModalStatus} onClose={authModalActions.handleClose} />
    </AuthorizationContext.Provider>
  );
};
