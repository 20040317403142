import clsx from 'clsx';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as CleanIcon } from 'assets/icons/clean.svg';
import { ReactComponent as GenerateIcon } from 'assets/icons/generate.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as WebIcon } from 'assets/icons/web.svg';
import { RoundButton } from 'components/ui/RoundedButton/RoundedButton';
import { useLayoutForm } from 'hooks/use-layout-form';
import { useLayout } from 'queries/useLayout';
import { useAuth } from 'hooks/use-auth';
import { useProfile } from 'queries/useProfile';

import { AsidePresentationsList } from './AsidePresentationsList';
import { AsidePresentation } from './AsidePresentation';

const Root = styled('aside')({
  gridArea: 'aside',
  minWidth: '113px',
  padding: '24px 0 16px',
  display: 'grid',
  gridTemplateAreas: `
    "buttons"
    "logo"
  `,
  gridTemplateRows: '1fr auto',
});

export const Aside = () => {
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const { openAuth } = useAuth();
  const { profile } = useProfile();
  const { form, isError, toggleIsError } = useLayoutForm();
  const { isGenerating, pptx } = useLayout();
  const submitForm = () => {
    if (isError) {
      setTimeout(() => {
        toggleIsError(false);
      }, 0);
    } else {
      document.querySelector('#layout-form')?.dispatchEvent(new Event('submit'));
    }
  };
  if (!form) return null;

  const { formState, reset } = form;

  if (pathname === '/presentation')
    return (
      <Root>
        <AsidePresentationsList />
        <Box display="flex" justifyContent="end">
          <Logo width="93px" height="48px" />
        </Box>
      </Root>
    );

  if (params.id)
    return (
      <Root>
        <AsidePresentation isGenerating={isGenerating} downloadPPTX={() => pptx.mutate(params.id!)} />
        <Box display="flex" justifyContent="end">
          <Logo width="93px" height="48px" />
        </Box>
      </Root>
    );

  return (
    <Root>
      <Stack gridArea="buttons" alignItems="flex-end" spacing={2}>
        {profile.data ? (
          <RoundButton sx={{ overflow: 'hidden' }} onClick={() => navigation('/profile')} title="Profile">
            <img alt="user-pic" src={`${process.env.REACT_APP_BASE_URL}/${profile.data.avatar}`} />
          </RoundButton>
        ) : (
          <RoundButton onClick={openAuth} title="Sign In">
            <UserIcon />
          </RoundButton>
        )}
        <RoundButton title="All presentations" onClick={() => navigation('/presentation')}>
          <WebIcon />
        </RoundButton>
        <RoundButton disabled={!formState.isDirty} onClick={() => reset()} title="Reset">
          <CleanIcon />
        </RoundButton>
        <RoundButton
          disabled={!formState.isValid}
          type={isError ? 'button' : 'submit'}
          onClick={submitForm}
          form={isError ? undefined : 'layout-form'}
          className={clsx(formState.isValid ? 'active' : '', isError && 'error')}
          loading={isGenerating > 0}
          title="Generate"
        >
          {isError && (
            <Box>
              <Typography color="primary.main" fontSize="14px">
                Something went wrong and your presentation couldn’t be generated. Try to change the the description and
                generate the presentation again.
              </Typography>
              <br />
              <Typography color="primary.main" fontSize="14px">
                OK
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex' }}>{isError ? <ErrorIcon /> : <GenerateIcon />}</Box>
        </RoundButton>
      </Stack>
      <Box display="flex" justifyContent="end">
        <Logo width="93px" height="48px" />
      </Box>
    </Root>
  );
};
