import { Components, inputBaseClasses } from '@mui/material';

export const MuiInput: Components['MuiInput'] = {
  styleOverrides: {
    root: {
      fontSize: '19px',
      color: '#E6E6E6',
      // ['& textarea::-webkit-input-placeholder, & textarea::-moz-placeholder']: {
      //   color: '#E6E6E6',
      //   opacity: 1,
      //   transition: 'color .1s ease-in-out',
      // },
      ['& input::placeholder, & textarea::placeholder']: {
        color: '#484848',
        opacity: 1,
      },
      ['&::after, &::before']: {
        display: 'none',
      },
      [`&.${inputBaseClasses.focused}`]: {
        ['& textarea::-webkit-input-placeholder']: {
          color: '#646464',
        },
      },
      ['& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button']: {
        margin: 0,
        WebkitAppearance: 'none',
      },
      ['& input[type=number]']: {
        MozAppearance: 'textfield',
      },
    },
  },
};
