import { Stack, styled } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { RoundButton } from 'components/ui/RoundedButton/RoundedButton';

const StyledRoundButton = styled(RoundButton)({
  '&:disabled': {
    opacity: 1,
    backgroundColor: '#ffffff',
    color: '#1F1F1F',
  },
});

export const AsidePresentationsList = () => {
  const navigation = useNavigate();
  const [params, setParams] = useSearchParams();
  const format = params.get('format');

  return (
    <Stack gridArea="buttons" alignItems="flex-end" spacing={2}>
      <RoundButton onClick={() => navigation('/')} title="Close">
        <CloseIcon />
      </RoundButton>
      {format === 'list' ? (
        <>
          <StyledRoundButton onClick={() => setParams(() => ({}))} title="Grid">
            <GridIcon />
          </StyledRoundButton>
          <StyledRoundButton disabled>
            <ListIcon />
          </StyledRoundButton>
        </>
      ) : (
        <>
          <StyledRoundButton disabled>
            <GridIcon />
          </StyledRoundButton>
          <StyledRoundButton onClick={() => setParams(() => ({ format: 'list' }))} title="List">
            <ListIcon />
          </StyledRoundButton>
        </>
      )}
    </Stack>
  );
};
