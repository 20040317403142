import { Suspense, ComponentType } from 'react';

const SuspenseResolver = (Component: ComponentType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SuspenseComponent = (props: any) => (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );

  // Add the display name here
  SuspenseComponent.displayName = `SuspenseResolver(${Component.displayName || Component.name || 'Component'})`;

  return SuspenseComponent;
};

export { SuspenseResolver };
