import { Components } from '@mui/material';

import { palette } from 'global/palette';

export const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      color: palette.primary.main,
      padding: '4px 0 5px',
      fontSize: '19px',
      fontWeight: '500',
    },
  },
};
